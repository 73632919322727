import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    ::-moz-selection {
      background-color: rgba(232, 59, 59, .4);
    }

    ::selection {
      background-color: rgba(232, 59, 59, .4);
    }
`;

export default GlobalStyle;
